'use client';

import { Layer, SnackbarElement, useTheme, WifiOffIcon } from '@otto-finance/ui';
import { useNetworkOnline } from 'common/roq-hooks/use-network-online';

export const NetworkOfflineMessage = () => {
  const [css] = useTheme();
  const { isOnline } = useNetworkOnline();
  if (isOnline) {
    return null;
  }
  return (
    <Layer>
      <div
        className={css({
          display: 'grid',
          justifyContent: 'center',
          paddingTop: '24px',
        })}
      >
        <SnackbarElement
          startEnhancer={() => <WifiOffIcon />}
          message={`You're offline. Please check your connection.`}
          focus={false}
        />
      </div>
    </Layer>
  );
};
