import {
  Appointment,
  AppointmentNote,
  AppointmentRoom,
  CoachCalendarInterface,
  TranscriptionParticipant,
} from 'common/interfaces';
import { caching } from 'configuration/data';
import {
  AttendAppointmentDocument,
  CancelSessionDocument,
  CreateAppointmentDocument,
  CreateSessionNoteDocument,
  DeleteSessionNoteDocument,
  EndAppointmentDocument,
  GenerateSessionSummaryDocument,
  GetAppointmentRecordingUploadUrlDocument,
  GetCoachCalendarsDocument,
  GetSessionDocument,
  GetSessionsDocument,
  GetTotalSessionsDocument,
  SessionNotesDocument,
  SessionRoomDocument,
  UpdateSessionDocument,
  UpdateTranscriptionDocument,
} from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import { AppointmentAttendeesDto, PagedAppointmentNote } from 'common/interfaces';
import { APPOINTMENT_TRANSCRIPTION_TYPE, AppointmentStatusEnum, SummaryTypeEnum } from 'enums';
import { compose } from '@reduxjs/toolkit';
import { parseSession, sessionFirstPipe, formatSessionSummary } from './pipes';
import { TranscriptionSpeaker } from 'common/interfaces/appointment.interface';

interface UpdateTranscriptionArgs {
  // sessionId?: string;
  // userId?: string;
  // sentence?: string;
  // timestamp?: string;
  summary?: string;
  appointmentId: string;
  reportSummary?: APPOINTMENT_TRANSCRIPTION_TYPE;
  reportComment?: string;
  participants?: TranscriptionParticipant[];
  speakers?: TranscriptionSpeaker[];
}

export interface AppointmentCreateDto {
  appointmentOwnerId?: string;
  attendees: AppointmentAttendeesDto;
  calendarId?: string;
  createdById?: string;
  description?: string;
  end?: string;
  externalId?: string;
  joinUrl?: string;
  profileId?: string;
  providerDescription?: string;
  start?: string;
  title: string;
  userId?: string;
  clientId?: string;
  reportType?: string;
  duration?: number;
  instant?: boolean;
  inPerson?: boolean;
  status?: AppointmentStatusEnum;
}

export interface GetSessionsArgs {
  participantUserId?: string;
  start?: string;
  end?: string;
  ids?: string[];
  limit?: number;
  offset?: number;
  participants?: string[];
  order?: {
    sort: string;
    order: string;
  };
  appointmentOwnerId?: string;
  userId?: string;
  appointmentParticipantId?: string;
  listNextAppointmentsOnly?: boolean;
  listPreviousAppointmentsOnly?: boolean;
  listPendingAppointmentsOnly?: boolean;
}

export const sessionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    attendAppointment: builder.mutation<boolean, { id: string }>({
      query: (args) => ({
        variables: args,
        document: AttendAppointmentDocument,
      }),
    }),
    cancelSession: builder.mutation<string, { id: string }>({
      query: (variables) => ({
        variables,
        document: CancelSessionDocument,
      }),
      invalidatesTags: (_, __, arg) => [
        API_TAGS.SESSIONS,
        API_TAGS.CALENDAR_AVAILABILITY,
        { type: API_TAGS.SESSION, id: arg.id },
      ],
    }),
    generateSessionSummary: builder.mutation<boolean, { id: string; type: SummaryTypeEnum }>({
      query: ({ id, type }) => ({
        variables: { appointmentId: id, type },
        document: GenerateSessionSummaryDocument,
      }),
    }),
    updateTranscription: builder.mutation<boolean, UpdateTranscriptionArgs>({
      query: (args) => ({
        variables: { input: args },
        document: UpdateTranscriptionDocument,
      }),
      async onQueryStarted({ appointmentId, summary }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            sessionsApi.util.updateQueryData('getSession', { id: appointmentId }, (draft) => {
              draft.transcription.summary.updated = summary;
              draft.transcription.dates.updatedAt = new Date().toISOString();
            }),
          );
        } catch {}
      },
    }),
    getSessions: builder.query<{ totalCount: number; data: Appointment[] }, GetSessionsArgs>({
      query: (variables) => ({
        variables,
        document: GetSessionsDocument,
      }),
      transformResponse: (response: { appointments: { totalCount: number; data: Appointment[] } }) => {
        const { totalCount, data } = response.appointments;
        return {
          totalCount,
          data: totalCount ? compose<Appointment[]>(sessionFirstPipe)(data) : [],
        };
      },
      keepUnusedDataFor: caching.fiveMinutes,
      providesTags: [API_TAGS.SESSIONS],
    }),
    getTotalSessions: builder.query<number, GetSessionsArgs>({
      query: (variables) => ({
        variables,
        document: GetTotalSessionsDocument,
      }),
      transformResponse: (response: { appointments: { totalCount: number } }) => {
        return response.appointments?.totalCount ?? 0;
      },
      keepUnusedDataFor: caching.day,
    }),
    getSession: builder.query<Appointment, { id: string }>({
      query: (variables) => ({
        variables,
        document: GetSessionDocument,
      }),
      transformResponse: (response: { appointment: Appointment }) =>
        compose<Appointment>(parseSession, formatSessionSummary)(response.appointment),
      keepUnusedDataFor: 0,
      providesTags: (_, __, arg) => [{ type: API_TAGS.SESSION, id: arg.id }],
    }),
    roomSession: builder.query<Appointment, { id: string }>({
      query: (variables) => ({
        variables,
        document: GetSessionDocument,
      }),
      transformResponse: (response: { appointment: Appointment }) => parseSession(response.appointment),
    }),
    createSessionNote: builder.mutation<AppointmentNote, { appointmentId: string; createdBy: string; note: string }>({
      query: (variables) => ({
        variables: { input: variables },
        document: CreateSessionNoteDocument,
      }),
      transformResponse: ({ createAppointmentNote }: { createAppointmentNote: AppointmentNote }) =>
        createAppointmentNote,
      invalidatesTags: [API_TAGS.SESSION_NOTES],
    }),
    deleteSessionNote: builder.mutation<string, { id: string }>({
      query: (variables) => ({
        variables,
        document: DeleteSessionNoteDocument,
      }),
      invalidatesTags: [API_TAGS.SESSION_NOTES],
    }),
    sessionNotes: builder.query<
      PagedAppointmentNote,
      { appointmentId: string; order?: { order: string; sort: string } }
    >({
      query: (variables) => ({
        variables,
        document: SessionNotesDocument,
      }),
      transformResponse: ({ appointmentNotes }: { appointmentNotes: PagedAppointmentNote }) => appointmentNotes,
      providesTags: [API_TAGS.SESSION_NOTES],
    }),
    updateSession: builder.mutation<Appointment, { id: string; data: Partial<AppointmentCreateDto> }>({
      query: ({ id, data }) => ({
        variables: { id, appointment: data },
        document: UpdateSessionDocument,
      }),
      invalidatesTags: (_, __, arg) => [API_TAGS.SESSIONS],
      transformResponse: ({ updateAppointment }: { updateAppointment: Appointment }) => updateAppointment,
    }),
    sessionRoom: builder.query<AppointmentRoom, { id: string }>({
      query: (variables) => ({
        variables,
        document: SessionRoomDocument,
      }),
      transformResponse: ({ appointmentRoom }: { appointmentRoom: AppointmentRoom }) => appointmentRoom,
    }),
    createSession: builder.mutation<Partial<Appointment>, AppointmentCreateDto>({
      query: (variables) => ({
        variables: { appointment: variables },
        document: CreateAppointmentDocument,
      }),
      invalidatesTags: [API_TAGS.SESSIONS, API_TAGS.CALENDAR_AVAILABILITY],
      transformResponse: ({ createAppointment }: { createAppointment: Appointment }) => createAppointment,
    }),
    coachCalendars: builder.query<CoachCalendarInterface, { userId: string }>({
      query: (variables) => ({
        variables,
        document: GetCoachCalendarsDocument,
      }),
      transformResponse: ({ appointmentOwnerCalendars }: { appointmentOwnerCalendars }) => appointmentOwnerCalendars,
    }),
    endSession: builder.mutation<boolean, { id: string }>({
      query: (variables) => ({
        variables,
        document: EndAppointmentDocument,
      }),
      invalidatesTags: (_, __, arg) => [API_TAGS.SESSIONS],
    }),
    sessionRecordingUploadUrl: builder.query<
      string,
      { appointmentId: string; fileName: string; contentType: string; maxFileSize: number }
    >({
      query: (variables) => ({
        variables: { data: variables },
        document: GetAppointmentRecordingUploadUrlDocument,
      }),
      transformResponse: ({ appointmentRecordingUploadUrl }: { appointmentRecordingUploadUrl }) =>
        appointmentRecordingUploadUrl,
    }),
  }),
});

export const {
  useAttendAppointmentMutation,
  useCancelSessionMutation,
  useCoachCalendarsQuery,
  useLazyCoachCalendarsQuery,
  useGenerateSessionSummaryMutation,
  useCreateSessionMutation,
  useCreateSessionNoteMutation,
  useDeleteSessionNoteMutation,
  useEndSessionMutation,
  useGetSessionQuery,
  useGetSessionsQuery,
  useGetTotalSessionsQuery,
  useLazyGetSessionQuery,
  useLazyGetSessionsQuery,
  useLazyGetTotalSessionsQuery,
  useLazyRoomSessionQuery,
  useLazySessionNotesQuery,
  useLazySessionRecordingUploadUrlQuery,
  useLazySessionRoomQuery,
  useRoomSessionQuery,
  useSessionNotesQuery,
  useSessionRoomQuery,
  useUpdateSessionMutation,
  useUpdateTranscriptionMutation,
} = sessionsApi;
