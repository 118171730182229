import { InvitationResendDto, InvitationResendResponse } from 'common/interfaces/invitation.interface';
import { baseApi } from './base.api';
import { ResendInvitationViaEmailDocument } from 'configuration/graphql/documents';

export const invitationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    resendInvite: builder.mutation<InvitationResendResponse, InvitationResendDto>({
      query: (input) => ({
        variables: { input },
        document: ResendInvitationViaEmailDocument,
      }),
    }),
  }),
});

export const { useResendInviteMutation } = invitationApi;
