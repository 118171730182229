// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"d5b66952af10b2adb303e1b3f14b24227c3dd7d0"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
// import packageInfo from './package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SHOW_DIALOG = process.env.NEXT_PUBLIC_SHOW_SENTRY_DIALOG === 'True';

// const RELEASE_VERSION = process.env.SENTRY_RELEASE || `${packageInfo.name}@${packageInfo.version}`;
Sentry.init({
  dsn: SENTRY_DSN || 'https://27b6cf9f4cb34231ab955cbb2a6049b5@o908608.ingest.sentry.io/5844300',
  // environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT !== 'development',
  // release: RELEASE_VERSION,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  // replaysOnErrorSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,
  beforeSend(event) {
    /**
     * List of errors for the showDialog to ignore but
     * still capture in Sentry
     */
    const dialogIgnoreErrors = ['websocket error'];

    const showDialogArgExists = event.extra?.showDialog !== undefined;
    const errorName = event.exception?.values?.[0]?.value;

    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && !dialogIgnoreErrors.includes(errorName) && SHOW_DIALOG) {
      const dialogOptions = {
        eventId: event.event_id,
        user: {
          email: event.user?.email || '',
          name: event.user?.username || '',
        },
      };

      if (showDialogArgExists) {
        if (event.extra.showDialog) {
          Sentry.showReportDialog(dialogOptions);
        }
      }
    }
    return event;
  },
});
