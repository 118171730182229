import { gql } from 'graphql-request';

export const AttendAppointmentDocument = gql`
  mutation AttendAppointment($id: String!) {
    attendAppointment(id: $id)
  }
`;

export const GenerateSessionSummaryDocument = gql`
  mutation GenerateSessionSummary($appointmentId: String!, $type: SummaryTypeEnum!) {
    generateSummary(appointmentId: $appointmentId, type: $type)
  }
`;

export const UpdateTranscriptionDocument = gql`
  mutation UpdateTranscription($input: TranscriptionUpdateDto!) {
    updateTranscription(input: $input)
  }
`;

export const GetSpeakersDocument = gql`
  query GetSpeakers($appointmentId: String!) {
    getSpeakers(appointmentId: $appointmentId) {
      index
      name
      role
      extendedRole
    }
  }
`;

export const CancelSessionDocument = gql`
  mutation CancelSession($id: String!) {
    deleteAppointment(id: $id)
  }
`;

export const GetSessionsDocument = gql`
  query GetSessions(
    $participantUserId: String
    $listNextAppointmentsOnly: Boolean
    $listPreviousAppointmentsOnly: Boolean
    $appointmentOwnerId: String
    $ids: [String!]
    $participants: [String!]
    $start: DateTime
    $end: DateTime
    $limit: Int
    $offset: Int
    $order: AppointmentOrderArgType
  ) {
    appointments(
      participantUserId: $participantUserId
      ids: $ids
      limit: $limit
      offset: $offset
      participants: $participants
      listNextAppointmentsOnly: $listNextAppointmentsOnly
      listPreviousAppointmentsOnly: $listPreviousAppointmentsOnly
      start: $start
      end: $end
      appointmentOwnerId: $appointmentOwnerId
      order: $order
    ) {
      data {
        id
        title
        description
        duration
        start
        end
        createdAt
        createdById
        appointmentOwnerId
        status
        appointmentStatus
        instant
        inPerson
        guestUrl
        videoProvider
        isOttoAppointment
        isRecallEnabled
        joinUrl
        appointmentNotes {
          totalCount
        }
        appointmentParticipants {
          data {
            externalName
            externalEmail
            id
            userId
            user {
              id
              type
              firstName
              lastName
            }
          }
        }
        transcription {
          id
          summary {
            original
            updated
          }
          reports {
            id
            status
          }
        }
      }
      totalCount
    }
  }
`;

export const GetTotalSessionsDocument = gql`
  query GetTotalSessions(
    $participantUserId: String
    $start: DateTime
    $end: DateTime
    $order: AppointmentOrderArgType
  ) {
    appointments(participantUserId: $participantUserId, start: $start, end: $end, order: $order) {
      totalCount
    }
  }
`;

export const GetSessionDocument = gql`
  query GetSession($id: String!) {
    appointment(id: $id) {
      appointmentNotes {
        totalCount
        data {
          note
          createdBy
          createdAt
        }
      }
      appointmentOwnerId
      appointmentParticipants {
        data {
          externalName
          externalEmail
          id
          userId
          user {
            id
            firstName
            lastName
            type
            email
          }
        }
      }
      createdAt
      createdById
      end
      id
      joinUrl
      start
      title
      duration
      description
      updatedAt
      reportType
      guestUrl
      inPerson
      status
      videoProvider
      isOttoAppointment
      isRecallEnabled
      transcription {
        id
        audioUrl
        dates {
          createdAt
          updatedAt
        }
        reports {
          id
          status
        }
        # summaries {
        #   text
        #   type
        # }
        summary {
          original
          updated
          type
        }
        paragraphs {
          text
          speaker
          start
          end
        }
        participants {
          userId
          index
          name
          role
        }
      }
    }
  }
`;

export const CreateSessionNoteDocument = gql`
  mutation CreateAppointmentNote($input: AppointmentNoteCreateDto!) {
    createAppointmentNote(appointmentNote: $input) {
      id
      note
      createdBy
      createdAt
      appointmentId
      createdAt
      updatedAt
    }
  }
`;

export const DeleteSessionNoteDocument = gql`
  mutation DeleteSessionNote($id: String!) {
    deleteAppointmentNote(id: $id)
  }
`;

export const SessionNotesDocument = gql`
  query AppointmentNotes($appointmentId: String!, $order: AppointmentNoteOrderArgType) {
    appointmentNotes(appointmentId: $appointmentId, order: $order) {
      totalCount
      data {
        id
        note
        createdBy
        createdAt
      }
    }
  }
`;

export const UpdateSessionDocument = gql`
  mutation UpdateAppointment($appointment: AppointmentUpdateDto!, $id: String!) {
    updateAppointment(appointment: $appointment, id: $id) {
      appointmentStatus
      createdAt
      createdById
      end
      externalId
      id
      joinUrl
      profileId
      start
      title
      updatedAt
      status
    }
  }
`;

export const SessionRoomDocument = gql`
  query AppointmentRoom($id: String!) {
    appointmentRoom(id: $id) {
      token
      room {
        id
        name
        url
      }
      recordingUrl
    }
  }
`;

export const CreateAppointmentDocument = gql`
  mutation CreateAppointment($appointment: AppointmentCreateDto!) {
    createAppointment(appointment: $appointment) {
      id
      status
      guestUrl
    }
  }
`;

export const EndAppointmentDocument = gql`
  mutation EndAppointment($id: String!) {
    endAppointment(id: $id)
  }
`;

export const GetCoachCalendarsDocument = gql`
  query AppointmentOwnerCalendars($userId: String!) {
    appointmentOwnerCalendars(userId: $userId) {
      calendars {
        calendarId
        calendarPrimary
      }
    }
  }
`;

export const GetAppointmentRecordingUploadUrlDocument = gql`
  query GetAppointmentRecordingUploadUrl($data: AppointmentUploadUrlArgType!) {
    appointmentRecordingUploadUrl(data: $data)
  }
`;
