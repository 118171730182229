export enum UserTypeEnum {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  COACH = 'coach',
}

export enum SpeakerExtendedRoleEnum {
  PARAPLANNER = 'paraplanner',
  INVESTMENT_MANAGER = 'investment_manager',
  ADMINISTRATOR = 'administrator',
  DIRECTOR = 'director',
  EMPLOYEE = 'employee',
  OTHER = 'other',
}
