import {
  ApplyCouponDocument,
  BenefitsDocument,
  CreateAndInviteCustomerDocument,
  CreateCoachMatchDocument,
  CreateCustomerMetaInfoDocument,
  CreateUserDocument,
  DeleteAccountDocument,
  DeleteAvatarDocument,
  SaveAvatarDocument,
  TiersDocument,
  UpdateCoachMatchDocument,
  UpdateCustomerMetaInfoDocument,
  UpdateUserChannelDocument,
  UpdateUserDocument,
  UserBenefitDocument,
  UserSubscriptionDocument,
} from 'configuration/graphql/documents';
import { API_TAGS, baseApi } from './base.api';
import { BenefitInterface, TierInterface, UserBenefitInterface, UserInterface } from 'common/interfaces';
import { caching } from 'configuration/data';
import { UserChannelEnum } from 'enums';

export interface UserModelInterface {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  avatarImageIdentifier?: string;
  type?: string;
  timezone: string;
  termsAcceptedAt?: string;
  privacyPolicyGlossaryId?: string;
  termsAndConditionsGlossaryId?: string;
  channel?: string;
  employerId?: string;
  coachMetaInfoId?: string;
  tierId?: string;
  partnerId?: string;
}

interface InvitationInviteDto {
  avatar?: string;
  avatarImageIdentifier?: string;
  channel?: string;
  coachMetaInfoId?: string;
  coupon?: string;
  customerMetaInfoId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  onboardingCompletedAt?: string;
  onboardingStep?: number;
  optedIn?: boolean;
  partnerId?: string;
  password?: string;
  privacyPolicyGlossaryId?: string;
  resetPasswordToken?: string;
  resetPasswordTokenExpires?: string;
  status?: string;
  subscriptionId?: string;
  termsAcceptedAt?: string;
  termsAndConditionsGlossaryId?: string;
  tierId?: string;
  timezone?: string;
  type?: string;
  validateEmailToken?: string;
  validateEmailTokenExpires?: string;
  coachMetaInfoToMatch?: string;
}

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation<UserInterface, { id: string; user: Partial<UserModelInterface> }>({
      query: (variables) => ({
        variables,
        document: UpdateUserDocument,
      }),
      transformResponse: (response: { updateUser }) => response.updateUser,
    }),
    createUser: builder.mutation<UserInterface, { input: InvitationInviteDto }>({
      query: (variables) => ({
        variables,
        document: CreateUserDocument,
      }),
      transformResponse: (response: { createAndInviteUser }) => response.createAndInviteUser,
    }),
    createAndInviteCustomer: builder.mutation<UserInterface, { input: InvitationInviteDto }>({
      query: (variables) => ({
        variables,
        document: CreateAndInviteCustomerDocument,
      }),
      transformResponse: (response: { createAndInviteCustomer }) => response.createAndInviteCustomer,
      invalidatesTags: [API_TAGS.USERS],
    }),
    saveAvatar: builder.mutation<{ id: string; url: string }, { fileId: string; status: string; userId?: string }>({
      query: (variables) => ({
        variables,
        document: SaveAvatarDocument,
      }),
      transformResponse: (response: { profilePictureUpdateFileStatus }) => response.profilePictureUpdateFileStatus,
    }),
    deleteAvatar: builder.mutation<string, { fileId: string; userId: string }>({
      query: (variables) => ({
        variables,
        document: DeleteAvatarDocument,
      }),
    }),
    deleteAccount: builder.mutation<string, { reason: string }>({
      query: (variables) => ({
        variables,
        document: DeleteAccountDocument,
      }),
    }),
    subscription: builder.query<string, { userId?: string; returnUrl: string }>({
      query: (variables) => ({
        variables,
        document: UserSubscriptionDocument,
      }),
      transformResponse: (response: { manageUserSubscription }) => response.manageUserSubscription,
    }),
    tiers: builder.query<TierInterface[], {}>({
      query: () => ({
        document: TiersDocument,
      }),
      transformResponse: (response: { tiers }) => response.tiers.data,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.TIERS],
    }),
    userBenefit: builder.query<UserBenefitInterface, { id: string }>({
      query: (variables) => ({
        variables,
        document: UserBenefitDocument,
      }),
      transformResponse: (response: { userBenefit }) => response.userBenefit,
    }),
    benefits: builder.query<BenefitInterface[], {}>({
      query: () => ({
        document: BenefitsDocument,
      }),
      transformResponse: (response: { benefits }) => response.benefits,
    }),
    updateChannel: builder.mutation<
      string,
      { benefit?: string; channel: UserChannelEnum; expiresAt?: string; sessions?: number; userId: string }
    >({
      query: (variables) => ({
        variables,
        document: UpdateUserChannelDocument,
      }),
    }),
    updateCoachMatch: builder.mutation<
      string,
      { id?: string; coachMatch: { coachMetaInfoId?: string; customerMetaInfoId?: string; isActive: boolean } }
    >({
      query: (variables) => ({
        variables,
        document: UpdateCoachMatchDocument,
      }),
    }),
    createCoachMatch: builder.mutation<
      string,
      { coachMatch: { coachMetaInfoId?: string; customerMetaInfoId?: string; isActive: boolean } }
    >({
      query: (variables) => ({
        variables,
        document: CreateCoachMatchDocument,
      }),
    }),
    createCustomerMetaInfo: builder.mutation<{ id: string }, { info: { customerCompanyId: string; userId: string } }>({
      query: (variables) => ({
        variables,
        document: CreateCustomerMetaInfoDocument,
      }),
      transformResponse: (response: { createCustomerMetaInfo }) => response.createCustomerMetaInfo,
    }),
    updateCustomerMetaInfo: builder.mutation<{ id: string }, { id: string; companyId: string }>({
      query: (variables) => ({
        variables,
        document: UpdateCustomerMetaInfoDocument,
      }),
      transformResponse: (response: { updateCustomerMetaInfo }) => response.updateCustomerMetaInfo,
    }),
    applyCoupon: builder.mutation<boolean, { code: string }>({
      query: (variables) => ({
        variables,
        document: ApplyCouponDocument,
      }),
      transformResponse: (response: { applyCoupon }) => response.applyCoupon,
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useSaveAvatarMutation,
  useDeleteAvatarMutation,
  useDeleteAccountMutation,
  useSubscriptionQuery,
  useTiersQuery,
  useUserBenefitQuery,
  useBenefitsQuery,
  useLazyUserBenefitQuery,
  useUpdateChannelMutation,
  useUpdateCoachMatchMutation,
  useCreateCoachMatchMutation,
  useCreateUserMutation,
  useCreateCustomerMetaInfoMutation,
  useUpdateCustomerMetaInfoMutation,
  useApplyCouponMutation,
  useCreateAndInviteCustomerMutation,
} = userApi;
