import { TranscriptionSpeaker } from 'common/interfaces';
import { GetSpeakersDocument } from 'configuration/graphql/documents/sessions.document';
import { SpeakerExtendedRoleEnum } from 'enums';
import { baseApi } from './base.api';

export const transcriptionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpeakers: builder.query<TranscriptionSpeaker[], { appointmentId: string }>({
      query: (variables) => ({
        variables,
        document: GetSpeakersDocument,
      }),
      transformResponse: (response: { getSpeakers: TranscriptionSpeaker[] }) =>
        response.getSpeakers.map((i) => ({
          ...i,
          extendedRole: i?.extendedRole?.toLowerCase() as SpeakerExtendedRoleEnum,
        })),
    }),
  }),
});

export const { useGetSpeakersQuery, useLazyGetSpeakersQuery } = transcriptionApi;
