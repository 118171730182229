import { InsightInputModel, InsightModel } from 'common/interfaces/insights.interface';
import {
  CreateInsightInputDocument,
  DeleteInsightDocument,
  FetchInsightByInputIdAndAppointmentIdDocument,
  FetchInsightInputsDocument,
  FetchInsightsByAppointmentIdDocument,
  GenerateInsightForInputAndAppointmentDocument,
  UpdateInsightInputDocument,
} from 'configuration/graphql/documents';
import { baseApi } from './base.api';

export const insightsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createInsightInput: builder.mutation<InsightInputModel, { prompt: string; name: string }>({
      query: ({ prompt, name }) => ({
        variables: { prompt, name },
        document: CreateInsightInputDocument,
      }),
    }),
    deleteInsight: builder.mutation<boolean, { id: string }>({
      query: ({ id }) => ({
        variables: { id },
        document: DeleteInsightDocument,
      }),
      transformResponse: (response: { deleteInsight: boolean }) => response.deleteInsight,
    }),
    fetchInsightByInputIdAndAppointmentId: builder.query<InsightModel, { inputId: string; appointmentId: string }>({
      query: ({ inputId, appointmentId }) => ({
        variables: { inputId, appointmentId },
        document: FetchInsightByInputIdAndAppointmentIdDocument,
      }),
      transformResponse: (response: { getInsightByInsightInputIdAndAppointmentId: InsightModel }) =>
        response.getInsightByInsightInputIdAndAppointmentId,
    }),
    fetchInsightInputs: builder.query<InsightInputModel[], void>({
      query: () => ({
        document: FetchInsightInputsDocument,
      }),
      transformResponse: (response: { getInsightInputs: InsightInputModel[] }) =>
        response.getInsightInputs.sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
    }),
    fetchInsightsByAppointmentId: builder.query<InsightModel[], { appointmentId: string }>({
      query: ({ appointmentId }) => ({
        variables: { appointmentId },
        document: FetchInsightsByAppointmentIdDocument,
      }),
      transformResponse: (response: { getInsightsByAppointmentId: InsightModel[] }) =>
        response.getInsightsByAppointmentId,
    }),
    generateInsightForInputAndAppointment: builder.mutation<
      InsightModel,
      { insightInputId: string; appointmentId: string }
    >({
      query: ({ insightInputId, appointmentId }) => ({
        variables: { insightInputId, appointmentId },
        document: GenerateInsightForInputAndAppointmentDocument,
      }),
      transformResponse: (response: { generateInsightForInputAndAppointment: InsightModel }) =>
        response.generateInsightForInputAndAppointment,
    }),

    updateInsightInput: builder.mutation<InsightInputModel, { id: string; prompt: string }>({
      query: ({ id, prompt }) => ({
        variables: { id, prompt },
        document: UpdateInsightInputDocument,
      }),
      transformResponse: (response: { updateInsightInput: InsightInputModel }) => response.updateInsightInput,
    }),
  }),
});

export const {
  useCreateInsightInputMutation,
  useDeleteInsightMutation,
  useFetchInsightByInputIdAndAppointmentIdQuery,
  useFetchInsightInputsQuery,
  useFetchInsightsByAppointmentIdQuery,
  useGenerateInsightForInputAndAppointmentMutation,
  useLazyFetchInsightByInputIdAndAppointmentIdQuery,
  useLazyFetchInsightsByAppointmentIdQuery,
  useUpdateInsightInputMutation,
} = insightsApi;
