import { SpeakerExtendedRoleEnum, UserTypeEnum } from 'enums';

export const TABLET_SIZE = 768;

export const DURATION = {
  LONG: 7000,
  SHORT: 3000,
  FOREVER: 0,
};

export const VERCEL_PROXY_TIMEOUT = 15;

export const GRAPHQL_API = `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`;
export const SESSION = {
  DURATION: 29, // minutes offset
};

export const STORAGE_KEYS = {
  HIDE_ASSIGN_PARTICIPANTS_MODAL: 'hide-assign-participants-modal',
};

export const userTypeMap = {
  [UserTypeEnum.CUSTOMER]: 'Client',
  [UserTypeEnum.COACH]: 'Adviser',
};

export const speakerExtendedRoleMap = {
  [SpeakerExtendedRoleEnum.PARAPLANNER]: 'Paraplanner',
  [SpeakerExtendedRoleEnum.INVESTMENT_MANAGER]: 'Investment Manager',
  [SpeakerExtendedRoleEnum.ADMINISTRATOR]: 'Administrator',
  [SpeakerExtendedRoleEnum.DIRECTOR]: 'Director',
  [SpeakerExtendedRoleEnum.EMPLOYEE]: 'Employee',
  [SpeakerExtendedRoleEnum.OTHER]: 'Other',
};
