import { gql } from 'graphql-request';

export const CreateInsightInputDocument = gql`
  mutation CreateInsightInput($name: String!, $prompt: String!) {
    createInsightInput(name: $name, prompt: $prompt) {
      id
      name
      prompt
      answerFormat
      createdAt
      updatedAt
    }
  }
`;

export const FetchInsightByInputIdAndAppointmentIdDocument = gql`
  query FetchInsightByInputIdAndAppointmentId($inputId: String!, $appointmentId: String!) {
    getInsightByInsightInputIdAndAppointmentId(inputId: $inputId, appointmentId: $appointmentId) {
      result
    }
  }
`;

export const FetchInsightsByAppointmentIdDocument = gql`
  query FetchInsightsByAppointmentId($appointmentId: String!) {
    getInsightsByAppointmentId(appointmentId: $appointmentId) {
      id
      result
      inputId
      transcriptionId
      appointmentId
    }
  }
`;

export const GenerateInsightForInputAndAppointmentDocument = gql`
  mutation GenerateInsightForInputAndAppointment($insightInputId: String!, $appointmentId: String!) {
    generateInsightForInputAndAppointment(insightInputId: $insightInputId, appointmentId: $appointmentId) {
      id
      result
      inputId
      transcriptionId
      appointmentId
    }
  }
`;

export const FetchInsightInputsDocument = gql`
  query FetchInsightInputs {
    getInsightInputs {
      id
      name
      prompt
      category
      answerFormat
      createdAt
      updatedAt
    }
  }
`;

export const UpdateInsightInputDocument = gql`
  mutation UpdateInsightInput($id: String!, $prompt: String!) {
    updateInsightInput(id: $id, prompt: $prompt) {
      id
      name
      prompt
      category
      answerFormat
      createdAt
      updatedAt
    }
  }
`;

export const DeleteInsightDocument = gql`
  mutation DeleteInsight($id: String!) {
    deleteInsight(id: $id)
  }
`;
