import { useEffect, useState } from 'react';

export const useNetworkOnline = () => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    const updateNetState = () => {
      if (window?.navigator) {
        setOnline(window.navigator.onLine);
      }
    };
    window.addEventListener('load', updateNetState);
    window.addEventListener('online', updateNetState);
    window.addEventListener('offline', updateNetState);

    return () => {
      window.removeEventListener('load', updateNetState);
      window.removeEventListener('online', updateNetState);
      window.removeEventListener('offline', updateNetState);
    };
  }, []);

  return { isOnline };
};
