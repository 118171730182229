import { gql } from 'graphql-request';

export const UploadDocumentToIntegrationDocument = gql`
  mutation uploadDocumentToIntegration(
    $document: IntegrationDocumentDto!
    $fileBase64: String!
    $provider: IntegrationProviderEnum!
    $userId: String!
  ) {
    uploadDocumentToIntegration(document: $document, fileBase64: $fileBase64, provider: $provider, userId: $userId)
  }
`;

export const ListDocumentsFromIntegrationDocument = gql`
  query listDocumentsFromIntegration($provider: IntegrationProviderEnum!, $userId: String!, $offset: Int, $limit: Int) {
    listDocumentsFromIntegration(provider: $provider, userId: $userId, offset: $offset, limit: $limit) {
      totalCount
      data {
        id
        title
        description
        downloadUrl
      }
    }
  }
`;
