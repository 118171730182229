import { addMinutes, subMinutes } from 'date-fns';
import { SESSION } from 'shared/constants';

export const isSessionActive = (start: string, end: string) =>
  new Date() > subMinutes(new Date(start), SESSION.DURATION) && new Date() < addMinutes(new Date(end), 35);
export const isSessionCancecable = (start: Date, end: Date) => {
  const now = Date.now();
  if (now < start.getTime()) {
    return true;
  }
  if (now >= start.getTime() && now <= end.getTime()) {
    return true;
  }
  return false;
};
export const isUpcomingSession = (start: string) => subMinutes(new Date(start), SESSION.DURATION) > new Date();
