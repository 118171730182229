import { gql } from 'graphql-request';

export const GetAccountsDocument = gql`
  query financeAccounts($userId: String!, $limit: Int, $offset: Int, $convertTo: String) {
    financeAccounts(userId: $userId, limit: $limit, offset: $offset, convertTo: $convertTo) {
      totalCount
      data {
        id
        alias
        dateAdded
        dateModified
        name
        policy
        type
        subtype
        providerName
        providerId
        providerReference
        connectionId
        balance
        currency
        vendor
        transactionData {
          count
          earliestDate
          lastDate
        }
        connection {
          id
          name
          type
          providerConnectionId
          expiresAt
          createdAt
          updatedAt
          errored
          errorAction
          actions
        }
        accountHolderName
        purpose
        details
        connection {
          expiresAt
        }
        convertedBalance
        convertedCurrency
        performance {
          total {
            openingBalance
            openingDate
            currentBalance
            currentDate
            currency
            contributions
            withdrawals
            nonContributionGrowth
            growthRate
            annualisedGrowthRate
          }
          monthly {
            period
            openingBalance
            nonContributionGrowth
            aer
          }
        }
        entity {
          name
          iconUrl
          iconBase64
        }
        holdings {
          accountId
          id
          vendor
          amount
          balance {
            amount
            convertedAmount
            convertedCurrency
            currency
          }
          security {
            symbol
            codes {
              figi
              isin
            }
            name
            exchange
            iconUrl
            price
            convertedPrice
            vendor
            sector {
              value
              percentage
            }
            country {
              value
              percentage
            }
            type
            class
            risk {
              assetClass
              countries {
                value
                percentage
              }
              regions {
                value
                percentage
              }
              sectors {
                value
                percentage
              }
              sectorsStability {
                value
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

export const GetCryptoAccountsDocument = gql`
  query cryptoAccounts($userId: String!, $limit: Int, $offset: Int, $convertTo: String) {
    cryptoAccounts(userId: $userId, limit: $limit, offset: $offset, convertTo: $convertTo) {
      totalCount
      data {
        id
        dateAdded
        dateModified
        name
        provider
        providerName
        type
        balance
        currency
        currencyName
        convertedBalance
        convertedCurrency
        convertedCurrencyName
      }
    }
  }
`;

export const RemoveAccountDocument = gql`
  mutation DeleteAccount($id: String!) {
    deleteDataIntegrationAccount(id: $id)
  }
`;

export const CreateManualAccountDocument = gql`
  mutation CreateManualAccount($account: AccountCreateDto!) {
    createDataIntegrationAccount(account: $account)
  }
`;

export const UpdateAccountDocument = gql`
  mutation UpdateAccount($id: String!, $account: AccountUpdateDto!) {
    updateDataIntegrationAccount(id: $id, account: $account)
  }
`;
