import clsx from 'clsx';
import { ChangeEvent, forwardRef, Ref, TextareaHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

export enum TextAreaSizeEnum {
  LARGE = 'large',
  SMALL = 'small',
}

export enum TextAreaStatusEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  DEFAULT = 'default',
}

export interface TextAreaInterface extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'> {
  /** Enable auto height for component */
  autosize?: boolean;
  /** Filled background of component */
  filled?: boolean;
  /** Component will have a full width of parent container */
  fullWidth?: boolean;
  /** Helper text bottom of component */
  helperText?: string;
  /** Label of component */
  label?: string;
  /** Max rows count of textarea */
  maxRows?: number;
  /** Min rows count of textarea */
  minRows?: number;
  /** Outlined borders of component */
  outline?: boolean;
  ref?: React.Ref<HTMLTextAreaElement>;
  /** Enable resize css property */
  resize?: boolean;
  /** Status success, error or default of component */
  status?: Lowercase<keyof typeof TextAreaStatusEnum>;
  /** Size of textarea */
  size?: Lowercase<keyof typeof TextAreaSizeEnum>;
}

/** Textarea component with auto height */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaInterface>(
  (
    {
      autosize,
      filled,
      outline,
      id,
      name,
      value,
      onFocus,
      onBlur,
      onChange,
      onKeyDown,
      className,
      placeholder,
      label,
      maxRows,
      minRows,
      disabled,
      helperText,
      status = TextAreaStatusEnum.DEFAULT,
      size = TextAreaSizeEnum.SMALL,
      autoComplete = 'false',
      fullWidth,
      resize = true,
      ...props
    },
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    interface TextAreaStateInterface {
      overflow?: boolean;
      outerHeightStyle?: number;
    }
    const [state, setState] = useState<TextAreaStateInterface>({});
    const [isInFocus, setIsInFocus] = useState<boolean>(false);

    const isDisabled = Boolean(props?.['aria-disabled']) || disabled;

    const textareaRef = useRef(null);
    const shadowRef = useRef(null);

    const classes = {
      wrapper: clsx({
        className,
        'w-full': fullWidth,
      }),
      innerWrapper: clsx('relative group flex flex-col justify-around box-border overflow-hidden', {
        'rounded-lg bg-label focus-within:bg-offWhite dark:bg-body': filled,
        'border-2 rounded-lg': outline,
        'opacity-50 pointer-events-none select-none': isDisabled,
        'min-h-14': size === TextAreaSizeEnum.SMALL,
        'min-h-16': size === TextAreaSizeEnum.LARGE,
        'border-label dark:border-background-dark-background6': status === TextAreaStatusEnum.DEFAULT && outline,
        'hover:border-grey2 dark:hover:border-background-dark-background6':
          status === TextAreaStatusEnum.DEFAULT && outline && !isInFocus,
        'focus-within:border-primary dark:border-background-dark-background4 dark:focus-within:border-background-dark-background6':
          status === TextAreaStatusEnum.DEFAULT,
        'border-error focus-within:border-error': status === TextAreaStatusEnum.ERROR,
        'border-success focus-within:border-success': status === TextAreaStatusEnum.SUCCESS,
      }),
      input: clsx(
        'outline-none border-none flex-auto text-base text-title bg-transparent dark:text-offWhite my-1.5 pt-1',
        {
          'pl-2': outline,
          'resize-none': !resize,
          resize,
          'mr-1.5': resize && outline,
          'focus:border-error': status === TextAreaStatusEnum.ERROR,
          'focus:border-success': status === TextAreaStatusEnum.SUCCESS,
        },
      ),
      shadowInput: clsx('invisible absolute overflow-hidden h-0 top-0 left-0'),
      label: clsx('w-full text-xs tracking-wide font-medium mt-0.5', {
        'px-6': outline,
        'text-grey2 dark:text-offWhite': status === TextAreaStatusEnum.DEFAULT && !isInFocus,
        'text-primary dark:text-grey3': status === TextAreaStatusEnum.DEFAULT && isInFocus,
        'text-error': status === TextAreaStatusEnum.ERROR,
        'text-success': status === TextAreaStatusEnum.SUCCESS,
      }),
      helperText: clsx('text-sm tracking-wide font-medium mt-2', {
        'px-6': outline,
        'text-grey2 dark:text-offWhite': status === TextAreaStatusEnum.DEFAULT,
        'text-error': status === TextAreaStatusEnum.ERROR,
        'text-success': status === TextAreaStatusEnum.SUCCESS,
      }),
      rightIcon: clsx(
        'absolute z-10 top-1/2 transform -translate-y-1/2 right-2 cursor-pointer flex flex-row items-center',
      ),
      inputContainer: clsx('flex flex-row items-center justify-start'),
      outlineBorder: clsx('w-full absolute bottom-0 group-hover:border-b-2', {
        'border-b': !isInFocus,
        'border-b-2': isInFocus,
        'border-grey2 dark:group-hover:border-offWhite': status === TextAreaStatusEnum.DEFAULT && !isInFocus,
        'border-primary dark:border-primary-light': status === TextAreaStatusEnum.DEFAULT && isInFocus,
        'border-error': status === TextAreaStatusEnum.ERROR,
        'border-success': status === TextAreaStatusEnum.SUCCESS,
      }),
    };

    const syncHeight = useCallback(() => {
      if (autosize) {
        const textarea = textareaRef.current;
        const containerWindow: Window = (textarea.ownerDocument || document).defaultView || window;
        const computedStyle = containerWindow.getComputedStyle(textarea);

        if (computedStyle.width === '0px') {
          return;
        }

        const textareaShadow = shadowRef.current;
        textareaShadow.style.width = computedStyle.width;
        textareaShadow.value = textarea.value || placeholder || 'x';
        if (textareaShadow.value.slice(-1) === '\n') {
          textareaShadow.value += ' ';
        }

        const boxSizing = computedStyle.boxSizing;
        const padding: number = parseInt(computedStyle.paddingBottom, 10) + parseInt(computedStyle.paddingTop, 10);
        const border: number =
          parseInt(computedStyle.borderBottomWidth, 10) + parseInt(computedStyle.borderTopWidth, 10);

        const innerHeight = textareaShadow.scrollHeight - padding;

        textareaShadow.value = 'x';
        const singleRowHeight = textareaShadow.scrollHeight - padding;

        let outerHeight = innerHeight;

        if (minRows) {
          outerHeight = Math.max(Number(minRows) * singleRowHeight, outerHeight);
        }
        if (maxRows) {
          outerHeight = Math.min(Number(maxRows) * singleRowHeight, outerHeight);
        }
        outerHeight = Math.max(outerHeight, singleRowHeight);

        const outerHeightStyle = outerHeight + (boxSizing === 'border-box' ? padding + border : 0);
        const overflow = Math.abs(outerHeight - innerHeight) <= 1;

        setState((prevState) => {
          if (
            (outerHeightStyle > 0 && Math.abs((prevState.outerHeightStyle || 0) - outerHeightStyle) > 1) ||
            prevState.overflow !== overflow
          ) {
            return {
              overflow,
              outerHeightStyle,
            };
          }

          return prevState;
        });
      }
    }, [maxRows, minRows, placeholder]);

    useEffect(() => {
      if (autosize) {
        const handleResize = () => {
          syncHeight();
        };
        ref = textareaRef;
        void ref;
        const containerWindow = (textareaRef.current.ownerDocument || document).defaultView || window;
        containerWindow.addEventListener('resize', handleResize);
        return () => {
          containerWindow.removeEventListener('resize', handleResize);
        };
      }
    }, [syncHeight]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (autosize) syncHeight();
      if (onChange) {
        onChange(event);
      }
    };

    const onFocusHandler = (e) => {
      setIsInFocus(true);
      if (onFocus) {
        onFocus(e);
      }
    };

    const onBlurHandler = (e) => {
      setIsInFocus(false);
      if (onBlur) {
        onBlur(e);
      }
    };

    return (
      <label className={classes.wrapper} htmlFor={id}>
        <div className={classes.innerWrapper}>
          {label && <div className={classes.label}>{(isInFocus && label) || '\u00a0'}</div>}
          <div className={classes.inputContainer}>
            <textarea
              id={id || `${name}-id`}
              rows={minRows}
              ref={textareaRef}
              name={name}
              value={value}
              onChange={handleChange}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
              onKeyDown={onKeyDown}
              placeholder={isInFocus ? null : placeholder}
              disabled={isDisabled}
              autoComplete={autoComplete}
              className={classes.input}
              style={
                autosize
                  ? {
                      height: state.outerHeightStyle,
                      overflow: state.overflow ? 'hidden' : null,
                    }
                  : null
              }
              {...props}
            />
            {autosize && (
              <textarea
                aria-hidden
                readOnly
                tabIndex={-1}
                ref={shadowRef}
                className={`${classes.input} ${classes.shadowInput}`}
                style={{
                  transform: 'translateZ(0)',
                }}
              />
            )}
          </div>
          {!outline && <div className={classes.outlineBorder}></div>}
        </div>
        {helperText && <div className={classes.helperText}>{helperText || '\u00a0'}</div>}
      </label>
    );
  },
);

TextArea.displayName = 'TextArea';
