import { gql } from 'graphql-request';

export const PartnersFragment = gql`
  fragment PartnersFragment on PartnerModel {
    id
    name
    integrations {
      intelliflo {
        active
      }
    }
  }
`;

export const PartnersDocument = gql`
  query Partners {
    partners {
      data {
        ...PartnersFragment
      }
      totalCount
    }
  }
`;

export const PartnerQuery = gql`
  query Partner($id: String!) {
    partner(id: $id) {
      ...PartnersFragment
    }
  }
`;
