import { Action, ActionFile, CreateActionArgsInterface, UserInterface } from 'common/interfaces';
import { caching } from 'configuration/data';
import {
  CreateTaskDocument,
  DeleteTaskDocument,
  DeleteTaskFileDocument,
  GetChatUsersDocument,
  GetGoalsDocument,
  GetTaskDocument,
  GetTasksDocument,
  SaveTaskFileDocument,
  TaskFilesDocument,
  UpdateTaskDocument,
  UpdateTaskOrderDocument,
} from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import { TaskStatusEnum } from 'enums';

interface GetActionsArgs {
  assigneeUserId: string;
  taskType?: 'objective' | 'actionItem';
  taskLabelId?: string;
  limit?: number;
  offset?: number;
  status?: string;
}

export interface GoalsActions extends Action {
  children: {
    data: Action[];
  };
  remaining: number;
  progress: number;
}

interface GoalsResponse {
  data: GoalsActions[];
}

export interface ActionsUpdateInterface {
  summary?: string;
  description?: string;
  assigneeUserId?: string;
  parent?: string;
  createdBy?: string;
  updatedBy?: string;
  taskTypeId?: string;
  type?: string;
  taskStatusId?: string;
  status?: string;
  taskPriorityId?: string;
  priority?: string;
  deadlineAt?: string;
}

export enum ActionTagType {
  Actions = 'Actions',
}

export const actionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    kanban: builder.query<Action[], GetActionsArgs>({
      query: (arg) => ({
        variables: {
          taskType: 'actionItem',
          ...arg,
        },
        document: GetTasksDocument,
      }),
      transformResponse: ({ tasks: { data } }: { tasks: GoalsResponse }) => data,
      keepUnusedDataFor: caching.day,
      providesTags: (_, __, arg) => [{ type: API_TAGS.ACTIONS_KANBAN, id: arg.assigneeUserId }],
    }),
    goals: builder.query<GoalsActions[], GetActionsArgs>({
      query: (arg) => ({
        variables: {
          taskType: 'objective',
          ...arg,
        },
        document: GetGoalsDocument,
      }),
      transformResponse: ({ tasks: { data } }: { tasks: GoalsResponse }) =>
        data.map((g) => {
          g.remaining = g.children.data.filter((f) => f.taskStatus.name !== TaskStatusEnum.COMPLETED).length;
          g.progress = 0;
          return g;
        }),
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.ACTIONS_GOALS],
    }),
    tasks: builder.query<Action[], GetActionsArgs>({
      query: (arg) => ({
        variables: {
          taskType: 'actionItem',
          ...arg,
        },
        document: GetTasksDocument,
      }),
      transformResponse: ({ tasks: { data } }: { tasks: GoalsResponse }) => {
        data.sort((a, b) => a.taskOrder - b.taskOrder);
        return data.map((g) => {
          g.remaining = 0;
          g.progress = 0;
          return g;
        });
      },
      keepUnusedDataFor: caching['10mins'],
      providesTags: [API_TAGS.ACTIONS_TASKS],
    }),
    updateTaskOrder: builder.mutation<boolean, { userId: string; items: { id: string; order: number }[] }>({
      query: ({ userId, items }) => ({
        variables: { userId, args: items },
        document: UpdateTaskOrderDocument,
      }),
      invalidatesTags: [API_TAGS.ACTIONS],
    }),
    deleteAction: builder.mutation<string, { id: string }>({
      query: (args) => ({
        variables: args,
        document: DeleteTaskDocument,
      }),
      transformResponse: ({ deleteTask }: { deleteTask: string }) => deleteTask,
      invalidatesTags: [API_TAGS.ACTIONS],
    }),
    updateAction: builder.mutation<Action, { id: string; task: ActionsUpdateInterface }>({
      query: (args) => ({
        variables: args,
        document: UpdateTaskDocument,
      }),
      transformResponse: ({ updateTask }: { updateTask }) => updateTask,
      invalidatesTags: [API_TAGS.ACTIONS_TASKS],
    }),
    createTask: builder.mutation<Action, CreateActionArgsInterface>({
      query: (args) => ({
        variables: { task: args },
        document: CreateTaskDocument,
      }),
      transformResponse: ({ createTask }: { createTask: GoalsActions }) => createTask,
      invalidatesTags: [API_TAGS.ACTIONS_TASKS, API_TAGS.ACTIONS],
    }),
    task: builder.query<Action, { id: string }>({
      query: (arg) => ({
        variables: arg,
        document: GetTaskDocument,
      }),
      transformResponse: ({ getTask }: { getTask: Action }) => getTask,
      keepUnusedDataFor: 0,
    }),
    actionUsers: builder.query<UserInterface[], { ids: string[] }>({
      query: (variables) => ({
        variables: { ...variables, limit: 100, offset: 0 },
        document: GetChatUsersDocument,
      }),
      transformResponse: (response: { users }) => response.users?.data,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.ACTIONS_USERS],
    }),
    saveActionFile: builder.mutation<ActionFile, { fileId: string; taskId: string; status: string }>({
      query: (variables) => ({
        variables,
        document: SaveTaskFileDocument,
      }),
      transformResponse: (response: { saveTaskFile }) => response.saveTaskFile,
    }),
    deleteActionFile: builder.mutation<string, { fileId: string; taskId: string }>({
      query: (variables) => ({
        variables,
        document: DeleteTaskFileDocument,
      }),
      invalidatesTags: (_, __, args) => [{ type: API_TAGS.ACTIONS_FILES, id: args.taskId }],
    }),
    actionFiles: builder.query<ActionFile[], { taskId: string }>({
      query: (variables) => ({
        variables,
        document: TaskFilesDocument,
      }),
      transformResponse: ({ taskFiles }) => taskFiles?.data || [],
      keepUnusedDataFor: caching.day,
      providesTags: (_, __, arg) => [{ type: API_TAGS.ACTIONS_FILES, id: arg.taskId }],
    }),
  }),
});

export const {
  useGoalsQuery,
  useUpdateTaskOrderMutation,
  useDeleteActionMutation,
  useUpdateActionMutation,
  useTasksQuery,
  useKanbanQuery,
  useCreateTaskMutation,
  useTaskQuery,
  useLazyTaskQuery,
  useLazyKanbanQuery,
  useLazyActionUsersQuery,
  useSaveActionFileMutation,
  useActionFilesQuery,
  useDeleteActionFileMutation,
} = actionsApi;
