import { gql } from 'graphql-request';

export const DataIntegrationAuthUrlDocument = gql`
  mutation DataIntegrationAuthorisationUrl($authData: GetAuthorisationUrlDto!) {
    dataIntegrationAuthorisationUrl(authData: $authData) {
      authorisationUrl
      state
      authRequestId
      linkToken
    }
  }
`;

export const RenewDataIntegrationAuthDocument = gql`
  mutation RenewDataIntegrationAuth($authData: RenewAuthorisationDto!) {
    renewDataIntegrationAuthorisation(authData: $authData) {
      authorisationUrl
      linkToken
      state
      authRequestId
    }
  }
`;

export const RevokeDataIntegrationAuthDocument = gql`
  mutation RevokeDataIntegrationAuth($authData: RevokeAuthorisationDto!) {
    revokeDataIntegrationAuthorisation(authData: $authData)
  }
`;
