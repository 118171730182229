import { gql } from '@apollo/client';

export const GetRecallSettingsDocument = gql`
  query GetRecallSettings($userId: String!) {
    getRecallSettings(userId: $userId) {
      id
      enableRecordingExternalMeetings
      enableRecordingInternalMeetings
      botName
      botImage
      createdAt
      updatedAt
    }
  }
`;

export const UpdateRecallSettingsDocument = gql`
  mutation UpdateRecallSettings($userId: String!, $input: UpdateRecallSettingsDto!) {
    updateRecallSettings(userId: $userId, input: $input) {
      id
      enableRecordingExternalMeetings
      enableRecordingInternalMeetings
      botName
      botImage
      createdAt
      updatedAt
    }
  }
`;

export const CancelRecallBotDocument = gql`
  mutation CancelRecallBot($appointmentId: String!) {
    cancelRecallBot(appointmentId: $appointmentId) {
      message
      success
    }
  }
`;

export const ScheduleRecallBotDocument = gql`
  mutation ScheduleRecallBotDocument($appointmentId: String!) {
    scheduleRecallBot(appointmentId: $appointmentId) {
      message
      success
    }
  }
`;
