import {
  AvailabilityDocument,
  CalendarAvailabilityDocument,
  SyncCalendarDocument,
  MigratePushNotificationDocument,
  UpdateAvailabilityDocument,
} from 'configuration/graphql/documents/calendar.document';
import { API_TAGS, baseApi } from './base.api';
import { CoachAvailabilityDay } from 'common/interfaces';
import { caching } from 'configuration/data';
import { getDayOfWeekInt } from 'utils/dates';
import { gql } from '@apollo/client';

export const calendarApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    availability: builder.query<{ [dayNumber: number]: CoachAvailabilityDay }, undefined>({
      query: (variables) => ({
        document: AvailabilityDocument,
        variables,
      }),
      transformResponse: (response: { coachAvailability: { data } }) => {
        if (response?.coachAvailability?.data) {
          return response.coachAvailability.data.reduce((acc, c) => {
            const dayNumber = getDayOfWeekInt(c.dayOfWeek);
            acc[dayNumber] = {
              ...c,
              dayNumber,
              bufferTimeHours: c?.bufferTime ? Number(c.bufferTime.split(' ')[0]) : 0,
            };
            return acc;
          }, {});
        }
        return {};
      },
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.COACH_AVAILABILITY],
    }),
    updateAvailability: builder.mutation<CoachAvailabilityDay[], CoachAvailabilityDay[]>({
      query: (variables) => ({
        document: UpdateAvailabilityDocument,
        variables: { data: variables },
      }),
      invalidatesTags: [API_TAGS.COACH_AVAILABILITY],
    }),
    calendarAvailability: builder.query<
      any,
      { start: string; end: string; calendarId: string; userId: string; duration: number }
    >({
      query: (variables) => ({
        document: CalendarAvailabilityDocument,
        variables,
      }),
      transformResponse: (response: { appointmentOwnersAvailability: { serviceProviderEnabledUsers } }) =>
        response.appointmentOwnersAvailability.serviceProviderEnabledUsers,
      providesTags: [API_TAGS.CALENDAR_AVAILABILITY],
    }),
    syncCalendar: builder.mutation<string, string>({
      query: (userId) => ({
        document: SyncCalendarDocument,
        variables: { userId },
      }),
    }),
    migratePushNotification: builder.mutation<string, { userId: string; dryRun: boolean }>({
      query: (variables) => ({
        document: MigratePushNotificationDocument,
        variables,
      }),
    }),
  }),
});

export const {
  useCalendarAvailabilityQuery,
  useLazyCalendarAvailabilityQuery,
  useLazyAvailabilityQuery,
  useAvailabilityQuery,
  useUpdateAvailabilityMutation,
  useSyncCalendarMutation,
  useMigratePushNotificationMutation,
} = calendarApi;
