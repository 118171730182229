import { caching } from 'configuration/data';
import {
  ConversationDocument,
  ConversationsDocument,
  ConversationsUsersDocument,
  MarkConversationAsRead,
} from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import { MessageInterface } from './message.api';
import { OrderEnum } from 'enums';
import { ConversationInterface, UserInterface } from 'common/interfaces';

export interface ConversationMemberInterface {
  userId: string;
}

export interface ConversationUserInterface {
  conversationId: string;
  conversation?: {
    members: { data: ConversationMemberInterface[] };
    lastMessage?: Partial<MessageInterface>;
  } | null;
}

type ConversationsArgs = {
  order?: { sort: string; order: OrderEnum };
  offset?: number;
  limit?: number;
  memberId?: string;
  ids?: string[];
};

interface Members {
  data: {
    userId: string;
  }[];
  totalCount: number;
}

export interface ConversationsResponse extends ConversationInterface {
  members: Members;
}

export interface ConversationSearchUser extends UserInterface {
  conversationId: string;
}

export const conversationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    conversations: builder.query<{ totalCount: number; data: ConversationInterface[] }, ConversationsArgs>({
      query: (variables) => ({
        variables,
        document: ConversationsDocument,
      }),
      keepUnusedDataFor: caching.day,
      providesTags: (_result, _error, arg) => [{ type: API_TAGS.CONVERSATIONS, id: arg.memberId }],
      transformResponse: ({
        conversations: { data, totalCount },
      }: {
        conversations: { data: ConversationsResponse[]; totalCount: number };
      }) => {
        return {
          totalCount,
          data: data.map((c) => ({
            id: c.id,
            title: c.title,
            ownerId: c.ownerId,
            unreadCount: c.unreadCount,
            archived: c.archived,
            updatedAt: c.updatedAt,
            createdAt: c.createdAt,
            memberIds: c.members.data.map((m) => m.userId),
            messages: [],
          })),
        };
      },
    }),
    conversation: builder.query<ConversationInterface, { id: string }>({
      query: (variables) => ({
        variables,
        document: ConversationDocument,
      }),
      keepUnusedDataFor: caching.day,
      transformResponse: ({ conversation }: { conversation: ConversationsResponse }) => {
        return {
          id: conversation.id,
          title: conversation.title,
          unreadCount: conversation.unreadCount,
          archived: conversation.archived,
          ownerId: conversation.ownerId,
          updatedAt: conversation.updatedAt,
          createdAt: conversation.createdAt,
          memberIds: conversation.members.data.map((m) => m.userId),
          messages: [],
        };
      },
    }),
    markConversationAsRead: builder.mutation<string, { conversationId: string; userId: string }>({
      query: (variables) => ({
        variables: {
          readConversation: variables,
        },
        document: MarkConversationAsRead,
      }),
    }),
    conversationUsers: builder.query<
      ConversationSearchUser[],
      { key?: string; ids?: string[]; multiColumnTextSearch?: string; limit?: number }
    >({
      query: ({ key, ...rest }) => ({
        variables: rest,
        document: ConversationsUsersDocument,
      }),
      transformResponse: ({ users: { data } }: { users: { data: UserInterface[] } }) =>
        data.map(({ customerMetaInfo, ...rest }) => ({
          ...rest,
          conversationId: customerMetaInfo?.coachMatches?.data[0]?.conversationId ?? null,
        })),
      keepUnusedDataFor: caching.day,
    }),
  }),
});

export const {
  useLazyConversationsQuery,
  useMarkConversationAsReadMutation,
  useConversationsQuery,
  useLazyConversationUsersQuery,
  useLazyConversationQuery,
} = conversationApi;
