import { Appointment } from 'common/interfaces';
import { AppointmentParticipant } from 'common/interfaces';
import { AppointmentStatusEnum, UserTypeEnum } from 'enums';
import { captureException } from 'utils';
import { isSessionActive } from 'utils/is-session-active';
import { parse } from 'marked';

export const sessionFirstPipe = (appointments: Appointment[]) => appointments.map(parseSession);

export const parseSession = (a: Appointment) => {
  try {
    const users = a.appointmentParticipants?.data ? sessionUsers(a.appointmentParticipants.data) : [];
    return {
      ...a,
      isActiveSession:
        a.inPerson && a.status !== AppointmentStatusEnum.ENDED
          ? true
          : a.start && a.status !== AppointmentStatusEnum.ENDED
          ? isSessionActive(a.start, a.end)
          : false,
      users,
      formalTitle: a.title,
      customerUser: users.find((f) => f?.type === UserTypeEnum.CUSTOMER),
    };
  } catch (error) {
    captureException(error);
    return null;
  }
};

export const formatSessionSummary = (session: Appointment) => {
  if (session.transcription?.summary && !session.transcription?.summary.updated) {
    session.transcription.summary.original = parse(session.transcription.summary.original, { async: false });
  }
  return session;
};

const sessionUsers = (participants: AppointmentParticipant[]) =>
  participants.map((p) => ({
    ...(p?.user
      ? p.user
      : {
          id: '',
          type: null,
          firstName: p.externalName,
          lastName: '',
          email: p.externalEmail,
        }),
  }));
