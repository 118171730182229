import { gql } from 'graphql-request';

export const ConversationsDocument = gql`
  query Conversations(
    $ids: [String!]
    $limit: Int
    $memberId: String
    $messageId: String
    $offset: Int
    $order: ConversationOrderArgType
    $ownerId: String
    $search: ConversationSearchArgType
  ) {
    conversations(
      memberId: $memberId
      ids: $ids
      limit: $limit
      messageId: $messageId
      offset: $offset
      order: $order
      ownerId: $ownerId
      search: $search
    ) {
      data {
        id
        title
        ownerId
        updatedAt
        createdAt
        archived
        unreadCount
        members {
          totalCount
          data {
            userId
          }
        }
      }
      totalCount
    }
  }
`;

export const ConversationDocument = gql`
  query Conversation($id: String!) {
    conversation(id: $id) {
      id
      title
      ownerId
      updatedAt
      createdAt
      archived
      unreadCount
      members {
        totalCount
        data {
          userId
        }
      }
    }
  }
`;

export const MarkConversationAsRead = gql`
  mutation markConversationAsRead($readConversation: ReadConversationDto!) {
    markConversationAsRead(readConversation: $readConversation)
  }
`;

export const ConversationsUsersDocument = gql`
  query ConversationsUsers($ids: [String!], $multiColumnTextSearch: String, $limit: Float) {
    users(ids: $ids, multiColumnTextSearch: $multiColumnTextSearch, limit: $limit) {
      data {
        id
        firstName
        lastName
        avatar
        customerMetaInfo {
          id
          coachMatches(isActive: true) {
            data {
              conversationId
            }
          }
        }
      }
      totalCount
    }
  }
`;
